<template>
  <div>
    <BaseTable
      :columns="columns"
      :resource="$data.$constants.RESOURCES.RESOURCE_FINANCIAL_LIMIT_REPORT"
      :loading="loading"
      :hideTotals="true"
      :hideFilterButton="true"
      :hideImportButton="false"
      :textImportButton="'Importar limites'"
      :disablePagination="true"
      :resourceStore="'financialLimitReports'"
      @import-button-click="importModal = true"
      @row-clicked="$router.push({ name: 'viewClient', params: { id: $event.id } })"
    >
      <template #cell(live_billing)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(not_collected)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(collected)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(financial_limit)="data">
        {{ data.value | numberToLocalString }} €
      </template>
    </BaseTable>
    
    <ImportFinancialLimitReportsModal v-model="importModal" />
  </div>
</template>

<script>
import BaseTable from "@/components/ui/table/BaseTable.vue";

export default {
  name: "FinancialLimitReportsTable",
  components: {
    BaseTable,
    ImportFinancialLimitReportsModal: () =>
      import(
        "@/components/financial-limit-reports/modal/ImportFinancialLimitReportsModal.vue"
      ),
  },
  data() {
    return {
      loading: false,
      importModal: false,
      columns: [
        {
          label: "CLIENTE",
          key: "name",
          sortable: false,
        },
        {
          label: "RECIBIDO DOC.PAGO",
          key: "collected",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "NO RECIBIDO",
          key: "not_collected",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "TOTAL DEUDA",
          key: "live_billing",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "LIMITE FINANCIERO",
          key: "financial_limit",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    };
  },
};
</script>